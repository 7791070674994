/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";

interface ComponentProps {
  content: {
    url: string;
    caption: string;
  };
}

const GalleryBlock = ({ content }: ComponentProps) => (
  <figure
    sx={{
      my: "3rem",
      mx: 0,
    }}
  >
    <img
      src={content.url}
      alt={content.caption}
      sx={{
        width: "100%",
      }}
    />
    <figcaption
      sx={{
        fontFamily: "heading",
        fontSize: rem(18),
        display: "block",
        mt: "-4px",
      }}
    >
      {content.caption}
    </figcaption>
    <hr
      sx={{
        mt: 0,
        bg: "black",
        height: "1px",
        border: "none",
      }}
    />
  </figure>
);

export default GalleryBlock;
