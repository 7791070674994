/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { rem } from "@src/theme";
import { Grid, Cell } from "@components/primitives/Grid";
import { cellAfterLine } from "@src/global-styles/verticalLine";

interface ComponentProps {
  content: {
    img1: {
      url: string;
    };
    img2: {
      url: string;
    };
    img3: {
      url: string;
    };
  };
}

const bgImageStyles = () => ({
  // backgroundImage: [`url(${url})`],
  // backgroundPosition: ["center"],
  // backgroundSize: ["cover"],
  zIndex: [2],
  position: ["relative"],
});

const GalleryBlock = ({ content }: ComponentProps) => (
  <React.Fragment>
    <Grid>
      <Cell
        gridColumn={["1 / span 3"]}
        styles={{
          ...cellAfterLine,
          "&:after": {
            ...cellAfterLine["&:after"],
            right: ["-9px", "-10px", "-13px", null, "-25px"],
            display: ["none", null, "block"],
          },
        }}
      >
        <div
          sx={{
            height: rem(48),
          }}
        ></div>
      </Cell>
    </Grid>

    <Grid>
      <Cell gridColumn={["1 / -1 ", null, "1 / span 6"]} gridRow={["1"]}>
        <div
          sx={{
            // height: rem(409),
            height: "100%",
            ...bgImageStyles,
          }}
        >
          <img
            src={content.img1.url}
            sx={{
              width: "100%",
              display: "block",
              verticalAlign: "bottom",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
      </Cell>
      <Cell
        gridColumn={["1 / span 3"]}
        styles={{
          ...cellAfterLine,
          "&:after": {
            ...cellAfterLine["&:after"],
            right: ["-9px", "-10px", "-13px", null, "-25px"],
          },
          display: ["none", null, "block"],
        }}
      >
        <div
          sx={{
            height: rem(200),
          }}
        ></div>
      </Cell>

      {content.img2 && (
        <Cell
          gridColumn={["1 / -1 ", null, "8 / span 9"]}
          gridRow={["2", null, "1 / span 2"]}
          styles={{
            pt: [rem(16), null, rem(32)],
          }}
        >
          <div
            sx={
              {
                // transform: [0, null, "translateY(47px)"],
              }
            }
          >
            <div
              sx={{
                ...bgImageStyles,
                py: [0, null, rem(47)],
              }}
            >
              <img
                src={content.img2.url}
                sx={{
                  width: "100%",
                  display: "block",
                  verticalAlign: "bottom",
                }}
              />
            </div>
          </div>
        </Cell>
      )}

      {content.img3 && (
        <Cell
          gridColumn={["1 / -1"]}
          styles={{
            pt: [rem(16), null, 0],
          }}
        >
          <div
            sx={{
              ...bgImageStyles,
            }}
          >
            <img
              src={content.img3.url}
              sx={{
                width: "100%",
                display: "block",
                verticalAlign: "bottom",
              }}
            />
          </div>
        </Cell>
      )}
    </Grid>

    <Grid>
      <Cell
        gridColumn={["1 / span 3"]}
        styles={{
          ...cellAfterLine,
          "&:after": {
            ...cellAfterLine["&:after"],
            right: ["-9px", "-10px", "-13px", null, "-25px"],
            display: ["none", null, "block"],
          },
        }}
      >
        <div
          sx={{
            height: rem(48),
          }}
        ></div>
      </Cell>
    </Grid>
  </React.Fragment>
);

export default GalleryBlock;
