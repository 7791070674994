/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import SEO from "@components/seo";
import Layout from "@components/layouts";
import { Section } from "@components/ContentBlocks";
import { Grid } from "@components/primitives/Grid";
export default ({ location }) => {
  return (
    <Layout location={location}>
      <SEO location={location} metadata={null} />
      <Grid>
        <Section>
          <p
            sx={{
              variant: "typography.bodyMedium",
            }}
          >
            For feedback and general enquiries, please contact <a
                sx={{
                  variant: "typography.link",
                  fontSize: 'inherit',
                  fontFamily: 'inherit',
                  color: 'coal'
                }}
            href="mailto:info@gippslandia.com.au">info@gippslandia.com.au</a>
          </p>

          <p
            sx={{
              variant: "typography.bodyMedium",
            }}
          >
           For a copy of our media kit or advertising enquiries, please contact <a
            sx={{
              variant: "typography.link",
              fontSize: 'inherit',
              fontFamily: 'inherit',
              color: 'coal'
            }}
           href="mailto:md@gippslandia.com.au">md@gippslandia.com.au</a>
          </p>

          <p
            sx={{
              variant: "typography.bodyMedium",
            }}
          >
If you’re looking to contribute a story, please click <a
 sx={{
  variant: "typography.link",
  fontSize: 'inherit',
  fontFamily: 'inherit',
  color: 'coal'
}}
href="https://gippslandia.com.au/contribute/">here</a> for further information.
          </p>

        </Section>
      </Grid>
    </Layout>
  );
};
