/** @jsx jsx */
import { jsx } from "theme-ui";
import articleStyles from "@src/global-styles/article";
import { Grid, Cell } from "@components/primitives/Grid";
import GalleryBlock from "@components/GalleryBlock";
import ImageCaptionBlock from "@components/ImageCaptionBlock";
import { cellBeforeLine as verticalLineStyle } from "@src/global-styles/verticalLine";

const cellBeforeLine = {
  ...verticalLineStyle,
  "&:before": {
    ...verticalLineStyle["&:before"],

    height: "64px",
  },
};

const HTMLBlock = ({ content }) => (
  <div sx={articleStyles} dangerouslySetInnerHTML={{ __html: content }}></div>
);

export const SectionBlock = ({ type, ...props }) => {
  switch (type) {
    case "Craft_bodySections_html_BlockType":
      return (
        <Grid>
          <Section>
            <HTMLBlock content={props.content} />
          </Section>
        </Grid>
      );
    case "Craft_bodySections_gallery_BlockType":
      return <GalleryBlock content={props.content} />;
    case "Craft_bodySections_imageCaption_BlockType":
      return (
        <Grid>
          <Section>
            <ImageCaptionBlock content={props.content} />
          </Section>
        </Grid>
      );
    default:
      return <div />;
  }
};

export const Section = ({ children }) => (
  <Cell
    gridColumn={["1 / -1", null, "4 / span 10"]}
    styles={{
      ...cellBeforeLine,
      "&:before": {
        ...cellBeforeLine["&:before"],
        height: "100%",
        display: ["none", null, "block"],
      },
    }}
  >
    {children}
  </Cell>
);
