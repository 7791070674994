import { rem } from "@src/theme";

const colors = [
  "stormcloud",
  "blood",
  "lemon",
  "beech",
  "morning",
  "gum",
  "stream",
  "hakea",
  "smoke",
  "sap",
  "bubblegum",
  "lipstick",
  "reed",
  "squash",
  "lake",
  "pumpkin",
  "mint",
  "clay",
  "lavender",
  "twilight",
];

const main = {};
const reducer = (color) => {
  main[`blockquote.side-pull.${color}`] = {
    bg: color,
  };

  main[`blockquote.in-content.${color}`] = {
    bg: color,
  };
  return;
};
const blockquoteStyles = () => colors.forEach(reducer);
blockquoteStyles();

export const styles = {
  fontSize: ["1.5em!important", null, "1.32em!important"],
  blockquote: {
    fontSize: "1.91rem!important",
    my: rem(75),
    fontFamily: "body",
    lineHeight: 1.2,
    padding: ".75rem .65rem",
  },
  "blockquote.side-pull": {
    my: 0,
    width: "100%",
    mb: ["1rem", null, 0],
    maxWidth: ["none", null, rem(268)],
    backgroundColor: "blood",
    color: "black",
    padding: "20px 20px",
    float: ["none", null, "left"],
    marginLeft: [0, null, "-128px"],
    marginRight: [0, null, "2rem"],
    zIndex: 99,
    position: "relative",
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      float: "none",
      ml: 0,
    },
    "&::before": {
      content: "''",
      width: 0,
      height: 0,
      borderLeft: "2px solid transparent",
      borderRight: "20px solid transparent",
      borderTop: "20px solid #f9f9f9",
      position: "absolute",
      top: 0,
      left: ["2.2%", null, "5.5%"],
      transform: "translateY(-3px) rotate(90deg)",
    },
  },
  "blockquote.in-content": {
    width: "100%",
    maxWidth: ["none", "80%", null, rem(600)],
    mx: "auto",
    mb: ["1rem", null, 0],
    backgroundColor: "gum",
    color: "black",
    padding: "20px 20px",

    fontFamily: "body",
    position: "relative",
    "&::before": {
      content: "''",
      width: 0,
      height: 0,
      borderLeft: "2px solid transparent",
      borderRight: "20px solid transparent",
      borderTop: "20px solid #f9f9f9",
      position: "absolute",
      top: 0,
      left: ["3.2%"],
      transform: "translateY(-3px) rotate(90deg)",
    },
  },
  ...main,
};

const articleStyles = {
  fontFamily: "article",
  ...styles,
  // ...theme.styles,
  "h1,h2,h3,h4,h5": {
    fontFamily: "heading",
    lineHeight: 1.1,
    mt: 0,
    mb: rem(10),
  },
  "h2:not(:first-child), h3:not(:first-child)": {
    mt: "3rem",
  },
  "h1, h2": {
    fontSize: "1.92rem!important", //24
  },
  h3: { fontSize: "1.6rem" },
  h4: { fontSize: "1.4rem" },
  h5: { fontSize: "1rem", textTransform: "uppercase" },
  li: {
    paddingLeft: ".3em",
  },
  p: {
    mt: 0,
  },
  "a, strong": {
    fontFamily: "heading",
    color: "currentColor",
  },
  a: {
    fontSize: "0.9em",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  iframe: {
    width: "100%",
    my: rem(20),
  },
};

export default articleStyles;
